import Api from './Api'

export default {
    get(idAula) {
        return Api().get(`periodos/aula/${idAula}`);
    },
    getAll(idSubject) {
        return Api().get(`periodos/subject/${idSubject}`);
    },
    update(idAula){
        return Api().put('periodos/update/idAula', idAula);
    },
    insert(periodos){
        return Api().post('periodos/register', periodos);
    }
}