import Api from './Api'

export default {
    get(keyword, pageNumber, sort) {
        return Api().get(`avaliacoes?keyword=${keyword}&pageNumber=${pageNumber}&sort=${sort}`);
    },
    getOne(id) {
        return Api().get(`avaliacoes/${id}`);
    },
    add_respostas(data) {
        return Api().post(`avaliacoes/submit`, data);
    },
    update(data) {
        return Api().put(`avaliacoes/${data.id}`, data);
    }
}