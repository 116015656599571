import BannerService from '../../services/BannerService'

const state = () => ({
    loadingBanners: false,
    banners: [],
})
  
const mutations = {
    SET_BANNERS: (state, payload) => {
        state.banners = payload.itens
    },
    SET_LOADING_BANNERS: (state, payload) => {
        state.loadingBanner = payload
    },
}

const actions = {
    async fetchBanners({commit}){
        try{
            commit('SET_LOADING_BANNERS', true)
            const response = await BannerService.get();
            commit('SET_BANNERS', response.data)
            commit('SET_LOADING_BANNERS', false)
        } catch (error) {
            commit('SET_LOADING_BANNERS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getBanners: state => state.banners,
    getLoadingBanner: state => state.loadingBanner,
}

export default {
    state,
    getters,
    mutations,
    actions
};