import Api from './Api'

export default {
    get(keyword, pageNumber, sort, pageSize) {
        return Api().get(`subjects?keyword=${keyword}&pageNumber=${pageNumber}&sort=${sort}&pageSize=${pageSize}`);
    },
    getAll() {
        return Api().post(`subjects/all`);
    },
    getOne(idAula, idSubject) {
        return Api().post(`subjects/getSubject`, {idAula, idSubject});
    },
    update(idNotification){
        return Api().put('subject/update/visualizado', idNotification);
    }
}