import Api from './Api'

export default {
    // getOne(id) {
    //     return Api().get(`aulas/${id}`);
    // },
    get(keyword, pageNumber, sort, idCategory) {
        return Api().get(`aulas?keyword=${keyword}&pageNumber=${pageNumber}&sort=${sort}&idCategory=${idCategory}`);
    },
    update(idNotification){
        return Api().put('aulas/update/visualizado', idNotification);
    },
    setAulaConcluida(idAula){
        return Api().post('aulas/complited', idAula);
    },
    getAllAulasAssistidas() {
        return Api().post(`aulas/assistidas`);
    }
}