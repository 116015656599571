import AvaliacaoService from '../../services/AvaliacaoService'
import router from '../../router'

const state = () => ({
    loadingAvaliacao: false,
    avaliacao: null,
    avaliacoes: [],
    newAvaliacao:{
        description: '',
    },
    total: 0,
    pages: 0,
    page: 1,
    show_modal_new_avaliacao: false
})
  
const mutations = {
    SET_AVALIACAO: (state, payload) => {
        state.avaliacao =  payload
    },
    SET_AVALIACOES: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.avaliacoes = payload.itens
        }else{
            state.avaliacoes = [...state.avaliacoes, ...payload.itens]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total
    },
    SET_LOADING_AVALIACAO: (state, payload) => {
        state.loadingAvaliacao = payload
    },
    SET_SHOW_MODAL_NEW_AVALIACAO: (state, payload) => {
        state.show_modal_new_avaliacao = payload
    },
}

const actions = {
    async fetchAvaliacoes({commit}, { keyword = '', pageNumber = 1, sortBy = 'mais recentes' }){
        try{
            commit('SET_LOADING_AVALIACAO', true)
            const response = await AvaliacaoService.get(keyword, pageNumber, sortBy);
            commit('SET_AVALIACOES', response.data)
            commit('SET_LOADING_AVALIACAO', false)
        } catch (error) {
            commit('SET_LOADING_AVALIACAO', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchAvaliacao({commit}, id){
        try{
            commit('SET_LOADING_AVALIACAO', true)
            const response = await AvaliacaoService.getOne(id);
            commit('SET_AVALIACAO', response.data)
            commit('SET_LOADING_AVALIACAO', false)
        } catch (error) {
            commit('SET_LOADING_AVALIACAO', false);
            router.push({path: `/avaliacoes`});
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async submitAvaliacao({ commit, state }, formData) {
        try{
            commit('SET_LOADING_AVALIACAO', true)
            const body = {
                idAvaliacao: state.avaliacao.id,
                respostas: formData
            }
            const response = await AvaliacaoService.add_respostas(body);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            setTimeout(() => {
                router.push({path: `/avaliacoes`});
            }, 3000);
            commit('SET_LOADING_AVALIACAO', false)
        } catch (error) {
            commit('SET_LOADING_AVALIACAO', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updadeAvaliacao({commit}, data){
        try{
            commit('SET_LOADING_AVALIACAO', true)
            const response = await AvaliacaoService.update(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_AVALIACAO', false)
        } catch (error) {
            commit('SET_LOADING_AVALIACAO', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getAvaliacao: state => state.avaliacao,
    getAvaliacoes: state => state.avaliacoes,
    getLoadingAvaliacao: state => state.loadingAvaliacao,
    getShowModalAvaliacao: state => state.show_modal_new_avaliacao,
}

export default {
    state,
    getters,
    mutations,
    actions
};