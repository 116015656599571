import CategoryService from '../../services/CategoryService'

const state = () => ({
    categoryLoading: false,
    categories: JSON.parse(localStorage.getItem('categories')) || [],

})
  
const mutations = {
    SET_CATEGORIES: (state, payload) => {
        state.categories = payload;
        localStorage.setItem('categories', JSON.stringify(payload));
    },
    SET_LOADING_CATEGORY: (state, payload) => {
        state.categoryLoading = payload
    },
}

const actions = {
    async fetchCategories({commit}){
        try {
            commit('SET_LOADING_CATEGORY', true);
            const response = await CategoryService.getAll();
            commit('SET_CATEGORIES', response.data);
            commit('SET_LOADING_CATEGORY', false);
        } catch (error) {
            commit('SET_LOADING_CATEGORY', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    }
}

const getters = {
    getCategories: state => state.categories,
    getLoadingCategory: state => state.categoryLoading,
}

export default {
    state,
    getters,
    mutations,
    actions
};